import EventDispatcher from "../../services/EventDispatcher";
import {generateUUID} from "../../helpers/strings";

export default class AbstractPixel {

    /**
     * @param {string} type
     * @param {PixelCollection} pixelCollection
     * @param {UserData} userData
     * @param {Context} context
     */
    constructor(type, pixelCollection, userData, context) {
        this.type = type;
        this.pixelCollection = pixelCollection;
        this.userData = userData;
        this.context = context;

        this._dispatcher = new EventDispatcher();
        this.subscribe(this._dispatcher);
    }

    /**
     * @private
     * @param {EventDispatcher} dispatcher
     */
    subscribe(dispatcher) {}

    /**
     * @public
     */
    initPixel() {}

    /**
     * @public
     * @param {AbstractEvent} event
     */
    trackEvent(event) {
        this.regenerateEventId(event);
        this._dispatcher.dispatch(event, this);
    }

    /**
     * @private
     * @param {AbstractEvent} event
     */
    regenerateEventId(event) {}

    /**
     * @protected
     * @return {string|*}
     */
    resolveUserId() {
        try {
            let id = localStorage.getItem('smp:u:i');
            if (!id) {
                id = window?.ShopifyAnalytics?.lib?.user().traits().uniqToken || generateUUID();
                localStorage.setItem('smp:u:i', id);
            }
            return id;
        } catch (e) {
            return generateUUID();
        }
    }
}
